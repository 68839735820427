import { InstallationOriginType } from '@wix/editor-platform-sdk-types';

export function isSilentInstallation(type?: InstallationOriginType) {
  if (!type) {
    return false;
  }

  return [
    InstallationOriginType.SILENT_INSTALL,
    InstallationOriginType.SITE_GENERATION,
    InstallationOriginType.SILENT_INSTALL_SITE_CREATION,
  ].includes(type);
}
